<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import helperFile   from '../../../helpers/helper';

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Approve Corporate Branch Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader},
  data() {
    return {
      
      tableData: [],

      title: "Approve Corporate Branch",
      items: [
        {
          text: "View",
        },
        {
          text: "Approve Corporate Branch",
          active: true,
        },
      ],
      confirmMsg:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          
          sortable: false,
        },
        {
          key: "corporateName",
          sortable: true,
        },
        {
          key: "corporateCode",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "branchName",
          sortable: true,
        },
        {
          key: "Address",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      
        
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readApprovalCorporates();
    this.substring = helperFile.substringFunction;
   
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readApprovalCorporates(){
         this.axios
        .post(this.$loggedRole+"/viewAllCorporateBranchForApproval", {empTypeID:this.$storageData.profile.empTypeID,approverLvl: this.$storageData.profile.approverLvl ? this.$storageData.profile.approverLvl : 0}
        )
        .then((response) => {
                   this.tableData = response.data.data;
      });
    },

    approveCorporate(corpBranchID){
            this.confirmMsg = "Are you sure you want to approve this Corporate?";
        if((this.$storageData.profile.empTypeID==15) || (this.$storageData.profile.empTypeID==1 && this.$storageData.profile.approverLvl==1)){
            this.confirmMsg = "Are you sure you want to make this Corporate live?";
        }
      this.$swal({
        title: "Are you sure?",
        text: this.confirmMsg,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Proceed!"
      }).then((result)=>{
        if(result.isConfirmed){
          this.axios.post(this.$loggedRole+"/approveCorporateBranch", {approverLvl: this.$storageData.profile.approverLvl ? this.$storageData.profile.approverLvl : 0,empTypeID:this.$storageData.profile.empTypeID,corpBranchID:corpBranchID}
              )
              .then((res) => {
                this.$swal({
                  text: res.data.message,
                  icon: "success",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000
                });
                this.readApprovalCorporates();
              }).catch((error)=>{
            this.$swal({
              text: error.response.data.message,
              icon: "error",
            });
          });
        }
      });       
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
           

                 <div class="row mt-4">
               <div class="col-sm-12 col-md-4" style="margin-bottom:15px;" v-if="this.$storageData.login_type == 1">
                    <div role="group" class="btn-group">
                     <download-excel
                        type="button" 
                        class="btn btn-themeBrown"
                        :data="tableData"
                        worksheet="Restaurant Branches"
                        name="restaurant_Branches.xls"
                      >
                        Excel
                      </download-excel>
                    </div>
               </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 table-striped">
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"

              >

              <template  v-slot:cell(Action)="data">
                  
                  <!-- <router-link :to="{ name: 'restaurantDetails', params: { id: data.item.restBranchID , type:'edit' } }">
                      <button class="btn btn-themeOrange mb-1" style="padding: 0px 7px;"> View </button>
                  </router-link> -->
                  <!-- <br> -->
                  <button v-if="data.item.isActive==3" class="btn btn-themeYellow" style="padding: 0px 7px;" @click="approveCorporate(data.item.corpBranchID)"> Approve </button>
                  <button v-else class="btn btn-themeYellow" style="padding: 0px 7px;" @click="approveCorporate(data.item.corpBranchID)"> Make Live </button>
                  <!-- <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->
                </template>

                <template  v-slot:cell(address)="data">
                  <span :title="data.item.address">{{substring(data.item.address)}}</span>

                </template>
                <template  v-slot:cell(Location)="data">
                  <span>{{data.item.branchName}}</span>

                </template>
                <template  v-slot:cell(restCharges)="data">
                  <span v-if="data.item.restCharges>0">₹{{data.item.restCharges}}</span>
                  <span v-else> 0 </span>
                </template>
                
                
                <template  v-slot:cell(avgPreparationTime)="data">
                  <span>{{data.item.avgPreparationTime}} mins</span>

                </template>
                
                

              </b-table>
                
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
